import React from "react"
import { Link } from "gatsby"
import Navigation from "../components/navigation"
import 'prismjs/themes/prism-okaidia.css';
import useSiteMetadata from "../hooks/use-site-metadata"

export default ({ children }) => {
  const {title} = useSiteMetadata()
 
  return (
    <div className="site-wrapper">
      <header className="site-header">
        <div className="site-title">
          <Link to="/">{title}</Link>
        </div>
        <Navigation />
      </header>
      {children}
      <footer className="site-footer">
        <p>&copy; 2020 Adestmedia &bull; Crafted with <span role="img" aria-label="love">❤️</span></p>
      </footer>
    </div>
  )
}